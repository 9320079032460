<template>
  <div id="designEdit">
    <div class="flex flex-col relative h-full">
      <NavBar>
        <div class="flex items-center">
          name:
          <input
            v-model="name"
            class="ml-2 mr-8 p-2"
            :class="{
              'cursor-[not-allowed]': this.$route.params.edit == 'true',
            }"
            type="text"
            :disabled="this.$route.params.edit == 'true'"
          />
          subject:
          <input v-model="subject" class="ml-2 p-2" type="text" />
          <button
            class="
              ml-4
              bg-gray-900
              hover:bg-black
              text-white
              font-bold
              py-2
              px-4
              rounded
              whitespace-nowrap
            "
            v-on:click="saveDesign"
          >
            <span>Save concept</span>
          </button>
          <button
            class="
              ml-4
              bg-gray-900
              hover:bg-black
              text-white
              font-bold
              py-2
              px-4
              rounded
              flex
              items-center
              justify-between
              whitespace-nowrap
            "
            v-on:click="addToSES"
          >
            <span class="pr-6">Deploy</span>
            <span v-if="saving" class="loader"></span>
          </button>
        </div>
      </NavBar>
      <EmailEditor ref="emailEditor" :appearance="{ theme: 'dark' }" />
    </div>
  </div>
</template>

<script>
import { executeApiCall } from '@/helpers/apiClient';
import { EmailEditor } from '../helpers';

import NavBar from '@/components/elements/NavBar.vue';

export default {
  name: 'designEdit',
  components: {
    NavBar,
    EmailEditor,
  },
  data() {
    return {
      name: '',
      subject: '',
      saving: false
    };
  },
  async beforeMount() {
    if (this.$route.params.key) {
      if (this.$route.params.edit == 'true') {
        this.name = this.$route.params.key;
      }

      const path = `/getTemplate/${this.$route.params.key}`;
      const res = await executeApiCall('get', path, null);

      this.subject = res?.data?.template.body.values.subject;
      this.$refs.emailEditor.editor.loadDesign(res?.data?.template);
    }
    
  },
  methods: {
    saveCheck() {
      if (
        !this.saving &&
        this.name &&
        this.name != '' &&
        this.subject &&
        this.subject != ''
      ) {
        return true;
      } else {
        return false;
      }
    },
    saveDesign() {
      if (this.saveCheck()) {
        this.saving = true;

        this.$refs.emailEditor.editor.exportHtml(async (data) => {
          this.$refs.emailEditor.editor.saveDesign(async (design) => {
            design.body.values.subject = this.subject;
            const path = `/saveRawTemplate`;
            const res = await executeApiCall('post', path, {
              name: this.name,
              design,
              html: data.html,
              subject: this.subject,
            });

            this.saving = false;
            if (res.ok) {
              alert('succes');
            } else {
              alert('err');
            }

          });
        });
      } else {
        alert(
          'heb je name en/of subject wel ingevuld? Als je de zelfde naam kiest overschij je de template anders maak je een kopie.'
        );
      }
    },
    addToSES() {
      if (this.saveCheck()) {
        this.saving = true;

        this.$refs.emailEditor.editor.exportHtml(async (data) => {

          const path = `/saveTemplate`;
          const res = await executeApiCall('post', path, {
            name: this.name,
            html: data.html,
            subject: this.subject,
          });

          this.saving = false;
          if (res.ok) {
            this.saveDesign();
          } else {
            alert('err');
          }

        });
      } else {
        alert(
          'heb je name en/of subject wel ingevuld? Als je de zelfde naam kiest overschij je de template anders maak je een kopie.'
        );
      }
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: rgb(59, 68, 80) !important;
  color: rgb(255, 255, 255) !important;
  border-color: rgb(14, 19, 24) !important;
  position: relative;
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#EmailTempBuilderapp,
#designEdit {
  height: 100%;
}

#designEdit .container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.loader {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #ff3d00 #ff3d00;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 12px;
  height: 12px;
  border-color: #fff #fff transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
