<template>
  <div class="card" :style="{ 'background-image': 'url(' + thumbnail + ')' }">
    <div class="card__overlay">
      <div class="card__header">
        <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
          <path />
        </svg>
        <img class="card__thumb" src="../assets/logo-144x144.png" alt="" />
        <div class="card__header-text">
          <h3 v-if="title" class="card__title">{{ title }}</h3>
          <span v-if="LastModified" class="card__status">{{
            format(new Date(LastModified), 'dd/MM/yyyy H:mm')
          }}</span>
        </div>
      </div>
      <div class="card__description flex flex-row">
        <router-link
          class="w-1/2 pl-1 mt-4"
          :to="{
            name: 'edit',
            params: {
              edit: true,
              key: title,
            },
          }"
        >
          <div
            class="
              ml-4
              bg-gray-900
              hover:bg-black
              text-white
              font-bold
              py-2
              px-4
              rounded
            "
          >
            edit
          </div>
        </router-link>
        <router-link
          class="w-1/2 pl-1 mt-4"
          :to="{
            name: 'edit',
            params: {
              edit: false,
              key: title,
            },
          }"
        >
        <div
            class="
              ml-4
              bg-gray-900
              hover:bg-black
              text-white
              font-bold
              py-2
              px-4
              rounded
            "
          >
            copy
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { format } from 'date-fns';
</script>

<script>
export default {
  name: 'EmailItem',
  data() {
    return {};
  },
  props: {
    thumbnail: {
      default: '/noPhoto.png',
    },
    title: {
      default: false,
    },
    LastModified: {
      default: false,
    },
  },
};
</script>

<style>
:root {
  --surface-color: #fff;
  --curve: 40;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem; /* calc */
  margin: 4rem 10vw;
  padding: 0;
  list-style-type: none;
}

.cards li {
  width: calc(25% - 2rem); /* calc */
}

@media only screen and (max-width: 1280px) {
  .cards li {
    width: calc(33% - 2rem); /* calc */
  }
}

@media only screen and (max-width: 1024px) {
  .cards li {
    width: calc(50% - 2rem); /* calc */
  }
}

@media only screen and (max-width: 768px) {
  .cards li {
    width: 80%; /* calc */
    margin: auto;
  }
}
</style>

<style scoped>
* {
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fef8f8;
}

.card {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 95%;
}

.card__image {
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
  color: rgb(41, 48, 57);
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em 1.5em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path('M 40 80 c 22 0 40 -22 40 -40 v 40 Z');
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  font-size: 1em;
  margin: 0 0 0.3em;
  color: rgb(41, 48, 57);
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: 'MockFlowFont';
  font-size: 0.8em;
  color: rgb(41, 48, 57);
}

.card__status {
  font-size: 0.8em;
  color: rgb(41, 48, 57);
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: rgb(41, 48, 57);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>