import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  persist: true, // Makes sure the store persists.

  state: () => ({
    isAuthenticated: false
  }),

  actions: {
    async setAuthenticated() {

      this.$patch({
        isAuthenticated: true
      });
    },

    async logoutUser() {
      this.$patch({
        isAuthenticated: false,
      });
    },
  },

  getters: {
    isLoggedIn: (state) => {
      return state.isAuthenticated;
    },
  }
});
