<template>
  <div class="login-container" onclick="onclick">
    <div class="top"></div>
    <div class="bottom"></div>
    <div class="center">
      <h2>Please Sign In</h2>
      <input v-model="userName" type="text" placeholder="username" />
      <input v-model="password" type="password" placeholder="password" />
      <div class="loginButton" @click="login">Login</div>
    </div>
  </div>
</template>
<script>
import { executeApiCall } from '@/helpers/apiClient';
import { useAuthStore } from '@/stores/auth';

export default {
  data() {
    return {
      userName: '',
      password: '',
    };
  },
  methods: {
    async login() {
      const { setAuthenticated } = useAuthStore();

      const path = '/login';
      const res = await executeApiCall('post', path, {
        username: this.userName,
        password: this.password,
      });

      if (res.ok && res?.data.auth) {
        setAuthenticated();
        this.$router.push({ name: 'DesignList' });
      }
    },
  },
};
</script>


<style scoped>
body {
  min-height: 100vh;
  font-family: 'Raleway', sans-serif;
}

h2 {
  color: #fff;
}

.login-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.login-container:hover .top:before,
.login-container:hover .top:after,
.login-container:hover .bottom:before,
.login-container:hover .bottom:after,
.login-container:active .top:before,
.login-container:active .top:after,
.login-container:active .bottom:before,
.login-container:active .bottom:after {
  margin-left: 200px;
  transform-origin: -200px 50%;
  transition-delay: 0s;
}
.login-container:hover .center,
.login-container:active .center {
  opacity: 1;
  transition-delay: 0.2s;
}

.top:before,
.top:after,
.bottom:before,
.bottom:after {
  content: '';
  display: block;
  position: absolute;
  width: 200vmax;
  height: 200vmax;
  top: 50%;
  left: 50%;
  margin-top: -100vmax;
  transform-origin: 0 50%;
  transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
  z-index: 10;
  opacity: 0.65;
  transition-delay: 0.2s;
}

.top:before {
  transform: rotate(45deg);
  background: #e46569;
}
.top:after {
  transform: rotate(135deg);
  background: #ecaf81;
}

.bottom:before {
  transform: rotate(-45deg);
  background: #60b8d4;
}
.bottom:after {
  transform: rotate(-135deg);
  background: #3745b5;
}

.center {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-left: -230px; /* dependent */
  margin-top: -230px; /* dependent */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px; /* dependent */
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
  transition-delay: 0s;
  color: #333;
}
.center input {
  width: 80%;
  padding: 15px;
  margin: 5px;
  border-radius: 1px;
  border: 1px solid #ccc;
  font-family: inherit;
}

.center input:placeholder {
  color: #fff;
}

.loginButton {
  cursor: pointer;
  margin-top: 5px;
  padding: 5px 15px;
  border: solid #000 1px;
  color: #fff;
}
</style>