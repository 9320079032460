import { createApp } from 'vue';
import router from '@/router';
import store from '@/stores';

const app = createApp({});

import '@/assets/styles/style.css';

app
  .use(store)
  .use(router)
  .mount('#EmailTempBuilderapp');

export default app;
