import { useAuthStore } from '@/stores/auth';

export const executeApiCall = async (method, path, data) => {
  const baseUrl = process.env.VUE_APP_BACKEND_URL ?? "http://localhost:3000";
  const { logoutUser } = useAuthStore();

  const headers = {
    'Content-Type': 'application/json',
  };

  const options = {
    method,
    headers,
    credentials: 'include',
  };

  if (data) {
    if (method.toLowerCase() === 'get') {
      const urlParams = new URLSearchParams(Object.entries(data));
      path = `${path}?${urlParams.toString()}`;
    } else {
      options.body = JSON.stringify(data);
    }
  }

  let response = null;

  try {
    response = await fetch(`${baseUrl}${path}`, options);
  } catch (e) {
    console.error(`Could not connect to API:`, e);
    throw new Error('Could not connect to API');
  }

  if (response.status == 401) {
    logoutUser()
  }

  let parsedResponse = null;

  try {
    parsedResponse = await response.json();
  } catch (e) {
    console.error(`Could not parse response as json:`, e);
    throw new Error('Could not parse response as json');
  }

  

  return {
    ok: response?.ok,
    data: parsedResponse,
  };
};
