import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth';

import LoginView from '@/views/LoginView.vue';
import DesignList from '@/views/DesignList.vue';
import DesignEdit from '@/views/DesignEdit.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/designList',
    name: 'DesignList',
    component: DesignList,
    meta: {
      requiresAuth: true,
    },
  },
  { path: '/create',
  component: DesignEdit,
    meta: {
      requiresAuth: true,
    }
  },
  { 
    path: '/edit',
    name: 'edit',
    component: DesignEdit,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  const { isLoggedIn } = useAuthStore();
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.name != 'Login'
  ) {
    if (!isLoggedIn) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    if (isLoggedIn && to.name == 'Login') {
      next({ name: 'DesignList' });
    } else {
      next();
    }
  }
});


export default router;